<template>
  <div>
    <van-nav-bar title="提现成功" left-arrow @click-left="onClickLeft" />
    <div class="succend">
      <div class="succend_1">
        <img
          style="width: 100px; height: 100px; margin-right: 9px"
          src="https://t.yhaocang.com/upload/sf_static/img/success/loser.png"
          alt=""
        />
        <div class="succend_2">{{ message }} 提现失败</div>
        <div class="succend_3">{{ message }}</div>
        <van-button round type="info" class="succend_4" @click="listClick"
          >知道了</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: this.$route.query.message
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    listClick() {
      this.$router.push("/data");
    }
  }
};
</script>

<style lang="scss" scoped>
.succend {
  height: 700px;
  width: 100%;
}
.succend_1 {
  text-align: center;
  margin-top: 100px;
}
.succend_2 {
  margin-top: 40px;
  font-size: 40px;
  font-weight: 600;
}
.succend_3 {
  margin-top: 20px;
  font-size: 30px;
  color: #999;
}
.succend_4 {
  margin-top: 30px;
  width: 200px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  font-size: 25px;
}
/deep/.van-nav-bar .van-icon {
  color: #000;
}
</style>
